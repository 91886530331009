/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { NetworkError } from "@apollo/client/errors";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "crypto-hash";
import { GraphQLFormattedError } from "graphql";
import { AdaErrorEvent } from "./AdaErrorEvent";
import { isPublicRoute } from "./AppRoute";
import { browserCacheLink } from "./browserCacheHttpLink";
import { config } from "./config";
import { refreshToken, signOut } from "./services/authService";
import { omitTypenameDeep } from "./utils/omitTypenameDeep";

const removeTypename = new ApolloLink((operation, forward) => {
  const newOperation = operation;
  newOperation.variables = omitTypenameDeep(newOperation.variables);
  return forward(newOperation);
});

const addLanguageHeader = setContext((_, { headers }) => {
  const language = localStorage.getItem(config.LANGUAGE_KEY);
  return {
    headers: {
      ...headers,
      "Accept-Language": language ?? "en",
    },
  };
});

const link = createHttpLink({
  uri: config.GRAPHQL_URL,
  credentials: "include",
});

const oneMinute = 1000 * 60;

const authHandler = new ApolloLink((operation, forward) => {
  const tokenExpiration = parseInt(
    document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${config.JWE_EXPIRATION_KEY}=`))
      ?.split("=")[1] ?? "-1",
    10
  );

  if (
    !isNaN(tokenExpiration) &&
    tokenExpiration > 0 &&
    tokenExpiration - oneMinute < Date.now()
  ) {
    // Refresh tokem preemptively
    refreshToken().catch((error) =>
      window.dispatchEvent(new AdaErrorEvent(error.message))
    );
  }

  return forward(operation);
});

const featureTogglesHandler = setContext((_, { headers }) => {
  const featureToggles = localStorage.getItem(config.FT_KEY);
  return {
    headers: {
      ...headers,
      "x-ada-feature-toggles":
        featureToggles && featureToggles.length > 0 ? featureToggles : "",
    },
  };
});

const checkJWTError = (
  errors: readonly GraphQLFormattedError[],
  keys: Array<string>,
  codes: Array<string> = []
) =>
  errors.some(
    (error) =>
      keys.some((key) =>
        error.message.toLowerCase().includes(key.toLowerCase())
      ) ||
      codes.some((code) =>
        String(error.extensions?.code)
          .toLowerCase()
          .includes(code.toLowerCase())
      )
  );
const checkNetworkError = (
  error: NetworkError | undefined,
  keys: Array<string>
) => keys.some((key) => error?.message.includes(key));

const errorHandler = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    if (
      operation.operationName === "getCurrentSchool" &&
      checkNetworkError(networkError, ["Failed to fetch"])
    ) {
      // eslint-disable-next-line no-console
      console.log("get current school error");
    }
    if (
      checkJWTError(
        graphQLErrors,
        ["Unauthorized", "Invalid token", "jwt expired", "Token expired"],
        ["UNAUTHENTICATED"]
      )
    ) {
      localStorage.removeItem(config.JWT_KEY);

      refreshToken()
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          if (
            window.location.pathname !== "/" &&
            !isPublicRoute(window.location.pathname)
          ) {
            window.location.assign(
              `${window.location.protocol}//${window.location.host}/?logoutReason=session-timeout&loginRedirect=`
            );
          }
        });
    } else if (
      checkJWTError(graphQLErrors, [
        "jwt malformed",
        "invalid signature",
        "Invalid refresh token",
        "School ID mismatch",
        "Failed to decrypt",
      ])
    ) {
      localStorage.removeItem(config.JWT_KEY);
      localStorage.removeItem(config.RT_KEY_JWE);
      if (
        window.location.pathname !== "/" &&
        !isPublicRoute(window.location.pathname)
      ) {
        window.location.assign(
          `${window.location.protocol}//${window.location.host}/?logoutReason=malformed-token&loginRedirect=`
        );
      }

      if (checkJWTError(graphQLErrors, ["School ID mismatch"])) {
        signOut().then(() => {
          window.location.reload();
        });
      }
    } else if (!checkJWTError(graphQLErrors, ["PersistedQueryNotFound"])) {
      const messages: Array<string> = [];
      graphQLErrors.forEach((error) => {
        const { message, path } = error;
        messages.push(message);
        // eslint-disable-next-line no-console
        console.error(
          `[GraphQL error]: Message: ${message}, Path: ${path}`,
          error
        );
      });
      window.dispatchEvent(new AdaErrorEvent(messages.join(", ")));
    }
  } else if (networkError) {
    if (
      operation.operationName === "getCurrentSchool" &&
      checkNetworkError(networkError, ["Failed to fetch"])
    ) {
      window.dispatchEvent(new AdaErrorEvent("School not found"));
    } else {
      // eslint-disable-next-line no-console
      console.error("[Network error]", networkError);
      window.dispatchEvent(new AdaErrorEvent(networkError.message));
    }
  }
});

const persistedQuery = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true,
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
  link: ApolloLink.from([
    removeTypename,
    addLanguageHeader,
    authHandler,
    featureTogglesHandler,
    browserCacheLink,
    persistedQuery,
    errorHandler,
    link,
  ]),
  credentials: "include",
});

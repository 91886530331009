/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FetchResult } from "@apollo/client";
import type { Dict } from "@chakra-ui/utils";
import React, { Dispatch, SetStateAction } from "react";
import {
  EntityName,
  School,
  User,
  UserProfile,
  UserRole,
} from "../schemaTypes";
import { TrackEventMutation } from "./graphql/trackEvent.generated";
type AvatarState = Pick<UserProfile, "avatarImageURL"> | null | undefined;

export type UserState =
  | (Pick<
      User,
      | "id"
      | "name"
      | "avatarUrl"
      | "roles"
      | "gitHubUsername"
      | "uploadedAvatarUrl"
    > & {
      profile?: AvatarState;
    })
  | null;

export type SchoolState = Pick<
  School,
  | "id"
  | "name"
  | "logoUrl"
  | "linkedInId"
  | "features"
  | "frontendFeatures"
  | "heroImageUrl"
  | "preferredLanguage"
  | "allowedLanguages"
  | "faviconUrl"
  | "loginLogoUrl"
  | "heroBackground"
  | "privacyUrl"
  | "conductCodeUrl"
  | "termsUrl"
  | "headerColor"
  | "headerAccent"
  | "gradingPolicyUrl"
  | "onboardingFlowId"
> | null;

export type UserHook = {
  user: UserState;
  isEditModeEnabled: boolean;
  theme: Dict;
  setUser: Dispatch<SetStateAction<UserState>>;
  setTheme: Dispatch<SetStateAction<Dict>>;
  currentSchool: SchoolState;
  setCurrentSchool: Dispatch<SchoolState>;
  setUserCoins: Dispatch<SetStateAction<number>>;
  userCoins: number;
  userLevel: number;
  setUserLevel: Dispatch<SetStateAction<number>>;
  setUserExperience: Dispatch<SetStateAction<number>>;
  userExperience: number;
  setIsEditModeEnabled: Dispatch<SetStateAction<boolean>>;
  isAdmin?: boolean;
  timezone: string;
  setTimezone: (timezone: string) => void;
  hasRoles: (roles: Array<UserRole>) => boolean;
  isMenuVisible?: boolean;
  setIsMenuVisible: Dispatch<SetStateAction<boolean>>;
  isMenuOpen?: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  recentCoursesIds: Array<string>;
  addRecentCourse: (courseId: string) => void;
  refetchUserStats: (delay?: number) => void;
  isUnlockedForUser: (requiredLevel: number) => boolean;
  lastUserStatsUpdate: number | undefined;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  trackEvent: ({
    event,
    cohortId,
    context,
    entity,
    entityId,
  }: {
    event: string;
    cohortId?: string;
    entity?: string;
    entityId?: EntityName | string;
    context?: Record<string, string>;
  }) => Promise<FetchResult<TrackEventMutation>>;
  currentTour: string | null;
  setCurrentTour: (tourName: string | null) => void;
};

export const UserContext = React.createContext<UserHook | null>(null);

/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export enum Language {
  EN = "en",
  ES = "es",
  PT = "pt",
}

export const mainLanguage: Language = Language.ES;

const memo = new Map();

export const normalizeLanguage = (language: string): Language => {
  if (memo.has(language)) {
    return memo.get(language);
  }

  if (language.toLowerCase().includes(Language.ES.toLowerCase())) {
    memo.set(language, Language.ES);
    return Language.ES;
  }

  if (language.toLowerCase().includes(Language.PT.toLowerCase())) {
    memo.set(language, Language.PT);
    return Language.PT;
  }

  memo.set(language, Language.EN);

  return Language.EN;
};

export const flagFromLanguage = (language: Language | string): string => {
  const normalized = normalizeLanguage(language);

  switch (normalized) {
    case Language.ES:
      return "🇪🇸";
    case Language.PT:
      return "🇵🇹";
    default:
      return "🇬🇧";
  }
};

export const languageName = (language: Language | string): string => {
  const normalized = normalizeLanguage(language);

  switch (normalized) {
    case Language.ES:
      return "Español";
    case Language.PT:
      return "Português";
    default:
      return "English";
  }
};
